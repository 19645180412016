import { List, ListItem } from 'flowbite-react'

export function AreaSelector({ onAreaClick }: { onAreaClick: (area: string) => void }) {
  return (
    <div className="text-sm">
      <List unstyled className="divide-y divide-gray-200">
        <ListItem className="font-bold hover:bg-gray-100 p-3" onClick={() => onAreaClick('ginza')}>
          銀座
        </ListItem>
        <ListItem
          className="font-bold hover:bg-gray-100 p-3"
          onClick={() => onAreaClick('yurakucho')}
        >
          有楽町
        </ListItem>
        <ListItem className="font-bold hover:bg-gray-100 p-3" onClick={() => onAreaClick('hibiya')}>
          日比谷
        </ListItem>
        <ListItem
          className="font-bold hover:bg-gray-100 p-3"
          onClick={() => onAreaClick('omotesando')}
        >
          表参道
        </ListItem>
        <ListItem
          className="font-bold hover:bg-gray-100 p-3"
          onClick={() => onAreaClick('harajuku')}
        >
          原宿
        </ListItem>
        <ListItem
          className="font-bold hover:bg-gray-100 p-3"
          onClick={() => onAreaClick('shibuya')}
        >
          渋谷
        </ListItem>
      </List>
    </div>
  )
}
