import * as Sentry from '@sentry/react'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  isRouteErrorResponse,
} from 'react-router'
import type { LinksFunction, MetaFunction } from 'react-router'

import stylesheet from './tailwind.scss?url'
import { ThemeModeScript } from 'flowbite-react'
import { COMMON_METADATA } from './utils/consts'

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
  },
  {
    rel: 'stylesheet',
    href: stylesheet,
  },
  {
    rel: 'icon',
    href: '/favicon.ico',
    sizes: '48x48'
  },
  {
    rel: 'icon',
    type: '/image/svg+xml',
    href: '/favicon.ico',
    sizes: 'any'
  },
  {
    rel: 'apple-touch-icon',
    href: '/apple-touch-icon-180x180.png',
  },
  {
    rel: 'manifest',
    href: '/manifest.webmanifest',
  }
]

export const meta: MetaFunction = () => {
  const title = 'imako - 今空いてるお店がわかるカフェ混雑マップ'
  return [
    ...COMMON_METADATA,
    { title },
    { name: 'apple-mobile-web-app-title', content: title },
    {
      name: 'description',
      content:
        '今空いてるお店がわかるカフェ混雑マップimako（いまこ）。銀座エリアのカフェから展開中！',
    },
    { name: 'keyword', content: 'カフェ,喫茶店,お茶,スイーツ,混雑,マップ,いまこ,imako,銀座,原宿,表参道,日比谷,有楽町' },
  ]
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="font-dmsans flex m-0 place-items-center overflow-hidden">
        <div className="w-screen h-dvh m-0 p-0">{children}</div>
        <ThemeModeScript />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>
    )
  } else if (error instanceof Error) {
    Sentry.captureException(error)
    return (
      <div>
        <h1>Oops, Error Occurred</h1>
        <p>{error.message}</p>
      </div>
    )
  } else {
    return <h1>Unknown Error</h1>
  }
}

function App() {
  return <Outlet />
}
export default App
