import { APIProvider } from '@vis.gl/react-google-maps'
import { SelectedSpot } from '~/models/SelectedSpot'
import { SiteInformationType } from '~/models/SiteInformationType'
import { ImakoMap } from '../ImakoMap'
import { useNotification } from '~/hooks/useNotification'
import { useFirstEnquete } from '~/hooks/useFirstEnquete'
import { useOnboarding } from '~/hooks/useOnboarding'
import { useGeolocation } from '~/hooks/useGeolocation'
import { useDeviceOrientation } from '~/hooks/useDeviceOrientation'
import { PropsWithChildren, useEffect } from 'react'
import { firebaseAnalytics } from '~/utils/firebase.client'
import { logEvent, setUserId } from 'firebase/analytics'
import { UserType } from '~/models/UserType'

export function MapContainer({
  userdata,
  defaultCenter,
  defaultZoom,
  onReloadCurrentTime,
  setPosition,
  selectedSpot,
  siteInformation,
  children,
}: PropsWithChildren<{
  userdata?: UserType
  defaultCenter?: string | null
  defaultZoom?: number
  onReloadCurrentTime?: () => void
  setPosition: (position: { lat: number; lng: number }) => void
  selectedSpot?: SelectedSpot
  siteInformation?: SiteInformationType | null
}>) {
  const { NotificationRequestModal, initNotification } = useNotification()
  const { Enquete, setShowFirstEnquete } = useFirstEnquete(false)
  const { OnboardingModal, setShowOnboarding } = useOnboarding(false)
  const { initGeo, removeGeo, currentPosition } = useGeolocation()
  const { initDeviceOrientation, currentHeading, watchDeviceOrientation, removeDeviceOrientation } =
    useDeviceOrientation()

  useEffect(() => {
    setShowFirstEnquete(false)
  }, [userdata])

  useEffect(() => {
    if (!userdata) return

    setShowOnboarding(!userdata?.completeOnboarding)
  }, [userdata])

  useEffect(() => {
    if (!userdata?.code) return

    setUserId(firebaseAnalytics, userdata?.code)
    return () => {
      setUserId(firebaseAnalytics, null)
    }
  }, [userdata])

  const isCompleteOnboarding = userdata?.completeOnboarding
  useEffect(() => {
    if (!isCompleteOnboarding) return
    const watchId = initGeo((opts) => {
      logEvent(firebaseAnalytics, 'geolocation', opts)
    })

    return () => {
      if (watchId) removeGeo(watchId)
    }
  }, [isCompleteOnboarding])

  useEffect(() => {
    if (!isCompleteOnboarding) return
    watchDeviceOrientation()

    return () => {
      removeDeviceOrientation()
    }
  }, [isCompleteOnboarding])

  useEffect(() => {
    if (!isCompleteOnboarding) return
    initNotification()
  }, [isCompleteOnboarding])

  useEffect(() => {
    if (currentPosition) setPosition(currentPosition)
  }, [currentPosition])

  const handleRequestPermission = (status: string) => {
    logEvent(firebaseAnalytics, 'deviceorientationpermission', {
      status,
    })
  }

  const handleOnboardingStart = () => {
    initGeo((opts) => {
      logEvent(firebaseAnalytics, 'geolocation', opts)
    })
    initDeviceOrientation(handleRequestPermission)
    initNotification()
  }

  const onMoveCenter = () => {
    logEvent(firebaseAnalytics, 'move_center', {})
    // もし初回オンボーディング経過後にとれなかったら再度取得する
    initDeviceOrientation(handleRequestPermission)
  }
  return (
    <>
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? ''}>
        <ImakoMap
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          currentPosition={currentPosition}
          currentHeading={currentHeading}
          selectedSpot={selectedSpot}
          siteInformation={siteInformation}
          onMoveCenter={onMoveCenter}
          onReloadCurrentTime={onReloadCurrentTime}
        />
        {children}
        {Enquete}
        {OnboardingModal(handleOnboardingStart)}
        {NotificationRequestModal}
      </APIProvider>
    </>
  )
}
