import { Outlet } from 'react-router'
import { isbot } from 'isbot'
import { isAuthenticated, logout } from '~/services/auth.server'
import { graphqlRequest } from '~/utils/graphqlRequest'
import { MeDocument, MeQuery } from '~/graphql/generated/graphql'
import type { Route } from './+types/_me'
import * as Sentry from '@sentry/node'

export async function loader({ request }: Route.LoaderArgs) {
  // botの場合は認証しない
  if (isbot(request.headers.get('user-agent') || '')) {
    return { me: { code: 'Anonymous', completeOnboarding: true } }
  }
  const session = await isAuthenticated(request)
  try {
    const { me, siteInformation } = await graphqlRequest<MeQuery>(MeDocument.toString(), session)
    return { me, siteInformation }
  } catch (error) {
    Sentry.captureException(error)
    console.error('login error', error)
    const url = new URL(request.url)
    const redirectTo = encodeURIComponent(url.pathname + url.search)
    return await logout(request, {redirectTo: `/login?redirectTo=${redirectTo}`})
  }
}

export const shouldRevalidate = () => false

export default function Me({ loaderData }: Route.ComponentProps) {
  const { me, siteInformation } = loaderData
  return <Outlet context={{ me, siteInformation }} />
}
