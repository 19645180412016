import { initializeApp, getApp, FirebaseApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInAnonymously, User } from 'firebase/auth'
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { firebaseAppOptions } from './firebaseOptions'

const APPNAME = 'imako-crowdmap'
let firebaseApp: FirebaseApp

try {
  firebaseApp = getApp(APPNAME)
} catch {
  firebaseApp = initializeApp(firebaseAppOptions, APPNAME)
}

const firebaseAuth = getAuth(firebaseApp)

let firebaseAnalytics: Analytics
try {
  firebaseAnalytics = getAnalytics(firebaseApp)
} catch (e) {
  console.error(e)
}

const firebaseGetToken = async () => {
  try {
    if (!(await isSupported())) return

    const firebaseMessaging = getMessaging(firebaseApp)
    const registration = await navigator.serviceWorker.getRegistration()
    return getToken(firebaseMessaging, {
      serviceWorkerRegistration: registration,
      vapidKey: import.meta.env.VITE_FIREBASE_MESSAGE_VAPID_KEY,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

type WaitUserPromiseArgType = {
  user: User
  token: string
}

const waitUser = () =>
  new Promise((resolve, _) => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user === null) {
        unsubscribe()
        const userCredential = await signInAnonymously(firebaseAuth)
        const { user } = userCredential
        const token = await user.getIdToken()
        resolve({ user, token })
        logEvent(firebaseAnalytics, 'login', { method: 'Firebase' })
      } else {
        unsubscribe()
        const token = await user.getIdToken()
        resolve({ user, token })
      }
    })
  })

export {
  firebaseAuth,
  firebaseApp,
  firebaseAnalytics,
  firebaseGetToken,
  waitUser,
  type WaitUserPromiseArgType,
}
