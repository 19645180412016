import { Button, Modal } from 'flowbite-react'
// import instagram_icon from '~/assets/instagram_icon.png'
// import imako_round from '~/assets/imako_round.png'
// import onboarding_1 from '~/assets/onboarding_1.png'
// import onboarding_2 from '~/assets/onboarding_2.png'
import { useState } from 'react'

// function Page1() {
//   return (
//     <div className="mb-10">
//       <h2 className="text-center">
//         はじめまして、
//         <span className="font-semibold">「今空いてるお店がわかる」アプリ</span>
//         いまこです！ .*♡
//       </h2>
//       <div className="flex justify-center items-center my-5">
//         <img src={imako_round} alt="imako_round" />
//       </div>
//       <p className="text-center text-sm">どこに行ってもカフェが混んでて、</p>
//       <p className="text-center text-sm">
//         諦めちゃうこと多いですよね...😿 ﾜｶﾘﾏｽ
//       </p>
//       <p className="pt-5 text-center text-sm">
//         「事前に店の混雑度が分かればいいのに」
//       </p>
//       <p className="text-center text-sm">「今空いてるお店を知りたい」</p>
//       <p className="text-center text-sm">
//         ...そんな想いからimakoを作りました！
//       </p>
//     </div>
//   )
// }

// function Page2() {
//   return (
//     <div className="mb-10">
//       <h2 className="text-center font-semibold">使い方１</h2>
//       <div className="flex justify-center items-center my-5">
//         <img src={onboarding_1} alt="onboarding_1" />
//       </div>
//       <p className="text-center text-sm">
//         はじめに、使い方の紹介をさせてください🙏
//       </p>
//       <p className="text-center text-sm">
//         地図上に、お店の一覧が表示されます。
//       </p>
//     </div>
//   )
// }

// function Page3() {
//   return (
//     <div className="mb-10">
//       <h2 className="text-center font-semibold">使い方2</h2>
//       <div className="flex justify-center items-center my-5">
//         <img src={onboarding_2} alt="onboarding_2" />
//       </div>
//       <p className="text-center text-sm">表示されるアイコンは、</p>
//       <p className="text-center text-sm">
//         <strong>imakoを開いている時刻</strong>の<strong>混雑度</strong>が
//       </p>
//       <p className="text-center text-sm">地図上に反映されます。</p>
//       <p className="text-center text-sm">(混雑状況はあくまでも目安です)</p>
//     </div>
//   )
// }

// function Page4() {
//   return (
//     <div className="mb-10">
//       <h2 className="text-center font-semibold">使い方3</h2>
//       <h3 className="text-center">＼インスタでも混雑情報の裏側を発信中！／</h3>
//       <div className="flex justify-center items-center my-5">
//         <img src={instagram_icon} alt="instagram_icon" />
//       </div>
//       <p className="text-center text-sm">
//         混雑情報は、実際に店舗を回ったり、
//         <br />
//         imakoの口コミや店舗に協力してもらうことで作り上げています。
//         <br />
//         ※あくまで参考情報として活用ください🙏
//       </p>
//     </div>
//   )
// }

function Page5({ onStart, handleClose }: { onStart: () => void; handleClose: () => void }) {
  return (
    <div className="my-10">
      <h2 className="text-center">＼現在は、お試しリリース期間中です／</h2>
      <div className="flex justify-center items-center my-5">
        <img src="/sorry_cat.png" alt="sorry_cat" />
      </div>
      <p className="text-center text-sm">
        「銀座・日比谷・有楽町・表参道エリアのカフェ」限定の利用です！
        <br />
        エリアを絞らないと情報を集められなくて😿🙏
        <br />
        <br />
        今後は、エリアや業態の拡大はもちろん、
        <br />
        お店から空き情報が配信されます♡
        <br />
        お楽しみに！🙏
      </p>
      <Button
        className="mx-auto size-sm mt-5"
        pill
        onClick={() => {
          onStart()
          handleClose()
        }}
      >
        位置情報をオンにしてスタート
      </Button>
    </div>
  )
}

export function Onboarding({
  startShow = true,
  onStart,
}: {
  startShow?: boolean
  onStart: () => void
}) {
  const [show, setShow] = useState(startShow)
  const [page, setPage] = useState(1)

  const handleClose = () => {
    setShow(false)
  }

  // const pages = [Page1, Page2, Page3, Page4, Page5]
  const pages = [Page5]

  return (
    <>
      <Modal show={show} position="center">
        <Modal.Body>
          {pages.map(
            (PageComponent, i) =>
              page === i + 1 && (
                <PageComponent key={i} onStart={onStart} handleClose={handleClose} />
              ),
          )}
          {pages.length > 1 && (
            <div className="flex justify-between">
              <div className="w-14">
                {page > 1 && (
                  <button
                    onClick={() => {
                      setPage((page) => (page <= 1 ? page : page - 1))
                    }}
                    className="text-sm"
                  >
                    &lt;&lt; 戻る
                  </button>
                )}
              </div>
              <div className="flex">
                {pages.map((_, i) => (
                  <div key={i} className="mx-1">
                    <button
                      onClick={() => setPage(i + 1)}
                      className={`w-2.5 h-2.5 ${
                        page === i + 1 ? 'bg-primary' : 'bg-gray-400'
                      } rounded-full`}
                    ></button>
                  </div>
                ))}
              </div>
              <div className="w-14">
                {page < pages.length && (
                  <button
                    onClick={() => {
                      setPage((page) => (page >= pages.length ? page : page + 1))
                    }}
                    className="text-sm"
                  >
                    次へ &gt;&gt;
                  </button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
