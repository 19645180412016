import { ClientOnly } from 'remix-utils/client-only'
import { Outlet, useOutletContext, useParams, useSearchParams } from 'react-router'
import { Header } from '~/components/Header'
import { ToastContainer } from 'react-toastify'
import { SiteInformationBar } from '~/components/SiteInformationBar'
import { SelectedSpot } from '~/models/SelectedSpot'
import { SiteInformationType } from '~/models/SiteInformationType'
import { firebaseAnalytics } from '~/utils/firebase.client'
import { logEvent } from 'firebase/analytics'
import { MapContainer } from '~/components/MapContainer'
import { Latlng } from '~/models/PositionType'
import { useEffect, useState } from 'react'
import { UserType } from '~/models/UserType'

type OutletContextType = {
  me: UserType,
  siteInformation: SiteInformationType
}

export default function Maps() {
  const { spotCode } = useParams()
  const { me: userdata, siteInformation } = useOutletContext<OutletContextType>()
  const [searchParams] = useSearchParams()
  const [position, setPosition] = useState<Latlng | undefined>()
  const [full, setFull] = useState(false)
  const [selectedSpot, setSelectedSpot] = useState<SelectedSpot | undefined>()

  useEffect(() => {
    if (!selectedSpot) {
      setFull(false)
    }
  }, [selectedSpot])

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        theme="light"
        hideProgressBar
      />
      <Header />
      { siteInformation && <SiteInformationBar siteInformation={siteInformation} /> }
      <div
        className={`absolute
                    ${ siteInformation ? 'md:top-20' : 'md:top-10' }
                    md:w-[410px] md:min-w-96 md:h-svh md:rounded-r-lg md:shadow-xl
                    w-svh h-svh
                    ${
                      spotCode
                        ? full
                          ? siteInformation ? 'translate-y-20 md:translate-x-0' 
                                            : 'translate-y-10 md:translate-x-0'
                          : 'translate-y-1/2 md:translate-x-0'
                        : 'translate-y-full md:-translate-x-full'
                    }
                    duration-300 transition md:translate-y-0
                    left-0 bottom-0 bg-white delay-75 z-10 rounded-t-lg overflow-hidden`}
      >
        <div
          className={`overflow-y-auto pb-10 ${full ? 'h-full' : 'h-1/2 md:h-svh pb-0 md:pb-10'}`}
        >
          <Outlet context={{ position, full, setFull, setSelectedSpot }} />
        </div>
      </div>
      <ClientOnly>
        {() => (
          <MapContainer
            userdata={userdata}
            setPosition={setPosition}
            defaultCenter={searchParams.get('c')}
            defaultZoom={parseFloat(searchParams.get('z') ?? '18')}
            selectedSpot={selectedSpot}
            siteInformation={siteInformation}
            onReloadCurrentTime={() => logEvent(firebaseAnalytics, 'reload_current_time', {})}
          ></MapContainer>
        )}
      </ClientOnly>
    </>
  )
}
