export const areaCenterPosition: { [key: string]: string } = {
  ginza: '35.67122,139.76505',
  yurakucho: '35.675073,139.762494',
  hibiya: '35.674569,139.759410',
  omotesando: '35.665213, 139.712416',
  harajuku: '35.668504,139.705330',
  shibuya: '35.658035,139.701916',
}
export const GINZA_CENTER = { lat: 35.67122, lng: 139.76505 }

export const COMMON_METADATA = [
  { name: 'chrome', content: 'nointentdetection' },
  { name: 'format-detection', content: 'telephone=no,email=no,address=no' },
  { name: 'theme-color', content: '#179AAE' },
]
