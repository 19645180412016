import { CollisionBehavior, useMap } from '@vis.gl/react-google-maps'
import { Cluster, type Marker, MarkerClusterer } from '@googlemaps/markerclusterer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFragment } from '~/graphql/generated'
import { SpotMarker } from './SpotMarker'
import { ClusterMarkerRenderer } from './ClusterMarkerRenderer'
import { expandBounds } from '~/utils/geoutil'
import { useNavigate } from 'react-router'
import {
  SpotsItemFragment,
  SpotsItemFragmentDoc,
  SpotsQueryQuery,
} from '~/graphql/generated/graphql'

export function ClusteredSpotMarkers({
  spots,
  showTitle,
}: {
  spots: SpotsQueryQuery['spots']
  showTitle: boolean
}) {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({})
  const map = useMap()
  const navigate = useNavigate()
  const clusterer = useMemo(() => {
    const algorithmOptions = { maxZoom: 22, radius: 100 }
    if (!map) return
    const renderer = new ClusterMarkerRenderer(navigate)
    const onClusterClick = (
      _: google.maps.MapMouseEvent,
      cluster: Cluster,
      map: google.maps.Map,
    ) => {
      if (!cluster.bounds) return
      map.fitBounds(expandBounds(cluster.bounds, 0.0001))
    }
    return new MarkerClusterer({ map, renderer, algorithmOptions, onClusterClick })
  }, [map, navigate])

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers
      if (marker) {
        return { ...markers, [key]: marker }
      } else {
        const { [key]: _, ...rest } = markers
        return rest
      }
    })
  }, [])

  const spotsDict = useMemo(() => {
    return Object.fromEntries(
      spots
        .map((spotFragment) =>
          useFragment<SpotsItemFragment>(SpotsItemFragmentDoc.toString(), spotFragment),
        )
        .map((spot) => [spot.code, spot]),
    )
  }, [spots])

  useEffect(() => {
    if (clusterer) {
      clusterer.clearMarkers(false)
      const filterMarkers = Object.entries(markers).filter(([k, _]) => !!spotsDict[k])
      clusterer.addMarkers(
        filterMarkers.map((v) => v[1]),
        false,
      )
      clusterer.render()
    }
    return () => {
      // reloadされたタイミングで古いものが残ってしまうのでマーカを消しておく
      clusterer?.clearMarkers()
    }
  }, [clusterer, markers, spotsDict])

  return (
    <>
      {spots.map((spotFragment) => {
        const spot = useFragment<SpotsItemFragment>(SpotsItemFragmentDoc.toString(), spotFragment)
        return (
          <SpotMarker
            key={spot.code}
            spotFragment={spotFragment}
            collisionBehavior={CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL}
            showTitle={showTitle}
            setMarkerRef={setMarkerRef}
          />
        )
      })}
    </>
  )
}
