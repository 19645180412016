import { Cluster, ClusterStats, DefaultRenderer, Marker } from '@googlemaps/markerclusterer'
import { SpotClusterMarkerElement } from './SpotClusterMarker'
import { NavigateFunction } from 'react-router'

export class ClusterMarkerRenderer extends DefaultRenderer {
  navigate: NavigateFunction

  constructor(navigate: NavigateFunction) {
    super()
    this.navigate = navigate
  }

  render(cluster: Cluster, stats: ClusterStats, map: google.maps.Map): Marker {
    if (!cluster.markers) return super.render(cluster, stats, map)
    if (cluster.marker) return cluster.marker

    const clusterMarkersInfo = cluster.markers.map((m) => {
      const advancedMarker = m as google.maps.marker.AdvancedMarkerElement
      const content = advancedMarker.content as HTMLElement
      return {
        name: advancedMarker.title,
        crowdedLevel: content.dataset?.crowdedLevel ?? 'noData',
        imageUrl: content.dataset?.imageUrl ?? '',
        code: content.dataset?.code ?? '',
      }
    })

    return SpotClusterMarkerElement(
      {
        position: cluster.position,
        showTitle: false,
        navigate: this.navigate,
        clusterMarkersInfo,
      },
      document.createElement('div'),
      map,
    )
  }
}
