import { useCallback, useState } from 'react'
import { Onboarding } from '../components/Onboarding'
import { useSubmit } from 'react-router'

export type OnboardingCompleteFunc = () => void

export function useOnboarding(show: boolean) {
  const [showOnboarding, setShowOnboarding] = useState(show)
  const submit = useSubmit()

  const OnboardingModal = useCallback(
    (onStartFunc: OnboardingCompleteFunc) => {
      return (
        showOnboarding && (
          <Onboarding
            onStart={() => {
              submit(
                {},
                {
                  action: '/users/onboarding/complete',
                  method: 'POST',
                  navigate: false,
                },
              )
              setShowOnboarding(() => false)
              onStartFunc()
            }}
          />
        )
      )
    },
    [showOnboarding],
  )
  return { OnboardingModal, setShowOnboarding }
}
