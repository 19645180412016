import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SiteInformationType } from '~/models/SiteInformationType'

type SiteInformationBarType = {
  siteInformation: SiteInformationType
}
export function SiteInformationBar({ siteInformation }: SiteInformationBarType) {
  if (!siteInformation) return <></>
  return (
    <div className="absolute top-10 left-0 w-full h-10 bg-info-notice z-20 items-center justify-center flex">
      <span className="font-semibold text-xs">
        <FontAwesomeIcon icon={faTriangleExclamation} size="sm" />
        {siteInformation.content}
      </span>
    </div>
  )
}