import {
  Map,
  useMap,
  MapControl,
  ControlPosition,
  MapCameraChangedEvent,
  MapEvent,
  useMapsLibrary,
} from '@vis.gl/react-google-maps'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, CustomFlowbiteTheme, Spinner, ToggleSwitch } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { useFragment } from '~/graphql/generated'
import { SpotsItemFragment, SpotsItemFragmentDoc } from '~/graphql/generated/graphql'
import { Latlng } from '~/models/PositionType'
import { ClusteredSpotMarkers } from '~/components/SpotMarker'
import { CurrentPositionMarker } from '~/components/CurrentPositionMarker'
import { useSpotsQuery } from '~/hooks/useSpotsQuery'
import { useNavigate } from 'react-router'
import { SelectedSpot } from '~/models/SelectedSpot'
import { SiteInformationType } from '~/models/SiteInformationType'
import { GINZA_CENTER } from '~/utils/consts'
import { expandBounds } from '~/utils/geoutil'

type Props = {
  defaultCenter?: Latlng | string | null
  defaultZoom?: number
  currentPosition: Latlng | undefined
  currentHeading: number
  selectedSpot?: SelectedSpot
  siteInformation?: SiteInformationType | null
  onMoveCenter?: () => void
  onReloadCurrentTime?: () => void
}

const toggleTheme: CustomFlowbiteTheme['toggleSwitch'] = {
  root: {
    base: 'group flex rounded-full focus:outline-none bg-white items-center px-3 drop-shadow-xl h-9 text-ellipsis overflow-hidden',
    label: 'ms-1 text-start text-xs font-medium text-gray-900 dark:text-gray-300',
  },
  toggle: {
    sizes: {
      sm: 'h-4 w-7 min-w-7 after:left-px after:top-px after:h-3 after:w-3 rtl:after:right-px',
    },
  },
}

export function ImakoMap({
  defaultCenter,
  defaultZoom = 19,
  currentPosition,
  currentHeading,
  selectedSpot,
  siteInformation,
  onMoveCenter,
  onReloadCurrentTime,
}: Props): JSX.Element {
  const mapId = import.meta.env.VITE_GOOGLE_MAPS_ID
  const map = useMap()
  const coreLib = useMapsLibrary('core')
  const [showTitle, setShowTitle] = useState(false)
  const navigate = useNavigate()
  const [currentBounds, setCurrentBounds] = useState<google.maps.LatLngBounds | null>(null)
  const [currentCenter, setCurrentCenter] = useState<google.maps.LatLng | null>(null)
  const { reloadFuncWithBbox, loading, data, previousBbox } = useSpotsQuery()
  const [filterNoCrowded, setFilterNoCrowded] = useState(true)

  const moveCenter = useCallback(() => {
    if (!currentPosition) return
    map?.panTo(currentPosition)
    if (onMoveCenter) onMoveCenter()
  }, [currentPosition, map, onMoveCenter])

  const handleCameraChanged = useCallback(
    (ev: MapCameraChangedEvent) => {
      setShowTitle(ev.detail.zoom > 18)
    },
    [setShowTitle, map],
  )

  const onReloadButtonClick = useCallback(() => {
    if (!currentBounds) return

    reloadFuncWithBbox(false, currentBounds)
    if (onReloadCurrentTime) onReloadCurrentTime()
  }, [onReloadCurrentTime, currentBounds, reloadFuncWithBbox])

  const handleIdle = useCallback(
    (e: MapEvent) => {
      const b = e.map.getBounds()
      if (b && !b.equals(currentBounds)) {
        setCurrentBounds(b)
        if (
          !previousBbox ||
          !previousBbox.contains(b.getNorthEast()) ||
          !previousBbox.contains(b.getSouthWest())
        ) {
          reloadFuncWithBbox(true, b)
        }
      }
      const c = e.map.getCenter()
      if (c?.equals(currentCenter)) setCurrentCenter(c)
    },
    [setCurrentBounds, reloadFuncWithBbox, previousBbox],
  )

  const handleClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  const filterByCrowdedInfo = useCallback(
    (spot: SpotsItemFragment, level: string) => {
      return !filterNoCrowded || (spot.crowdedLevel === level && spot.isOpen)
    },
    [filterNoCrowded],
  )

  const filterSpots = useMemo(() => {
    return data?.spots.filter((spotFragment) => {
      const spot = useFragment<SpotsItemFragment>(SpotsItemFragmentDoc.toString(), spotFragment)
      return (
        currentBounds &&
        spot.latitude &&
        spot.longitude &&
        expandBounds(currentBounds).contains({ lat: spot.latitude, lng: spot.longitude }) &&
        filterByCrowdedInfo(spot, 'noCrowded')
      )
    })
  }, [data, currentBounds, filterByCrowdedInfo])

  useEffect(() => {
    if (!map) return
    if (!defaultCenter) return

    if (typeof defaultCenter === 'string') {
      const [lat, lng] = defaultCenter.split(',')
      if (lat && lng) map.panTo({ lat: parseFloat(lat), lng: parseFloat(lng) })
    } else {
      map.panTo(defaultCenter)
    }
    map.setZoom(defaultZoom)
  }, [map, defaultCenter, defaultZoom])

  useEffect(() => {
    if (selectedSpot) {
      return
    }
  }, [selectedSpot])

  useEffect(() => {
    const moveCenter = () => {
      if (selectedSpot && map && coreLib) {
        const bounds = map.getBounds()
        const ne = bounds?.getNorthEast()
        const sw = bounds?.getSouthWest()

        const isMd = 'matchMedia' in window && window.matchMedia('(min-width: 768px)').matches

        if (ne && sw) {
          const height = Math.abs(ne.lat() - sw.lat())
          const width = Math.abs(ne.lng() - sw.lng())

          // 地図の上半分やや下に表示するような微調整
          let lat = selectedSpot.lat - height / 4.5
          let lng = selectedSpot.lng
          if (isMd) {
            lat = selectedSpot.lat
            lng = selectedSpot.lng - width / 8
          }
          setCurrentBounds(new coreLib.LatLngBounds(bounds))
          map.panTo({ lat, lng })
          return true
        }
      }
      return false
    }

    if (!moveCenter()) {
      const listenerId = map?.addListener('idle', () => {
        moveCenter()
        if (listenerId) {
          coreLib?.event?.removeListener(listenerId)
        }
      })
      return () => {
        if (listenerId) coreLib?.event?.removeListener(listenerId)
      }
    }
  }, [selectedSpot, map, coreLib])

  return (
    <>
      {loading && (
        <div
          className={`bg-gray-400/50 
                      absolute
                      top-50
                      left-0
                      w-full
                      h-full
                      z-10
                      overflow-y-visible
                      content-center
                      text-center`}
        >
          <Spinner aria-label="Loading..." size="xl" className="z-20" color="success" />
        </div>
      )}
      <Map
        defaultCenter={GINZA_CENTER}
        defaultZoom={defaultZoom}
        disableDefaultUI={true}
        minZoom={12}
        mapId={mapId}
        clickableIcons={false}
        gestureHandling="greedy"
        headingInteractionEnabled={true}
        onCameraChanged={handleCameraChanged}
        onIdle={handleIdle}
        onClick={handleClick}
      >
        {currentPosition && (
          <CurrentPositionMarker
            currentPosition={currentPosition}
            currentHeading={currentHeading}
          />
        )}

        <MapControl position={ControlPosition.TOP_CENTER}>
          <div className={`flex ${siteInformation ? 'mt-24' : 'mt-12'} gap-3`}>
            <Button
              color="gray"
              className="xw-fit h-9 rounded-full drop-shadow-xl"
              onClick={onReloadButtonClick}
            >
              <span className="text-xs">現在時刻で再取得する</span>
            </Button>
            <ToggleSwitch
              theme={toggleTheme}
              checked={filterNoCrowded}
              label="「入れるかも」のみ表示"
              onChange={setFilterNoCrowded}
              sizing="sm"
            />
          </div>
        </MapControl>

        <MapControl position={ControlPosition.RIGHT_BOTTOM}>
          <Button
            color="gray"
            className="ansolute w-10 h-10 bottom-10 right-5 rounded-full drop-shadow-xl flex items-center justify-center"
            onClick={moveCenter}
          >
            <FontAwesomeIcon icon={faLocationCrosshairs} />
          </Button>
        </MapControl>

        {filterSpots ? <ClusteredSpotMarkers spots={filterSpots} showTitle={showTitle} /> : null}
      </Map>
    </>
  )
}
