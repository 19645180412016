import { AdvancedMarker, AdvancedMarkerRef, CollisionBehavior } from '@vis.gl/react-google-maps'
import { FragmentType, useFragment } from '~/graphql/generated'
import { SpotHolder, SpotHolderLabel } from '../SpotHolder'
import { Marker } from '@googlemaps/markerclusterer'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { SpotsItemFragment, SpotsItemFragmentDoc } from '~/graphql/generated/graphql'

type Props = React.PropsWithChildren<{
  spotFragment: FragmentType<typeof SpotsItemFragmentDoc>
  showTitle: boolean
  collisionBehavior: CollisionBehavior | undefined
  setMarkerRef: (marker: Marker | null, key: string) => void
}>

export function SpotMarker({ spotFragment, showTitle, collisionBehavior, setMarkerRef }: Props) {
  const spot = useFragment<SpotsItemFragment>(SpotsItemFragmentDoc.toString(), spotFragment)
  const { spotCode } = useParams()
  const navigate = useNavigate()

  const onClick = () => {
    const code = spot?.code
    navigate(`/spots/${code}`, { replace: true })
  }

  const ref = useCallback(
    (marker: AdvancedMarkerRef) => {
      // marker にユーザデータをもたせる苦肉の策
      if (marker?.content) {
        const content = marker.content as HTMLElement
        content.dataset.imageUrl = spot.firstSpotPhoto?.imageUrl ?? ''
        content.dataset.code = spot.code
        content.dataset.crowdedLevel = spot.isOpen ? spot.crowdedLevel : 'noBusinessHours'
        content.dataset.name = spot.name
      }
      setMarkerRef(marker, spot.code)
    },
    [setMarkerRef],
  )

  const position = { lat: spot.latitude ?? 0, lng: spot.longitude ?? 0 }

  const highlight = spotCode == spot.code

  if (!spot.latitude && !spot.longitude) return

  return (
    <>
      <AdvancedMarker
        className="relative"
        position={position}
        collisionBehavior={collisionBehavior}
        title={spot.name}
        onClick={onClick}
        ref={ref}
        zIndex={highlight ? 10 : 1}
      >
        <SpotHolder
          crowdedLevel={spot.isOpen ? spot.crowdedLevel : 'noBusinessHours'}
          imageUrl={spot.firstSpotPhoto?.thumbnailImageUrl ?? ''}
          highlight={highlight}
        />
        {showTitle && <SpotHolderLabel text={spot.name} highlight={highlight} />}
      </AdvancedMarker>
    </>
  )
}
