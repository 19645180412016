import { useState } from 'react'

// https://stackoverflow.com/questions/60640018/devicemotionevent-request-permission-with-typescript
interface DeviceOrientationEventiOS extends DeviceOrientationEvent {
  requestPermission?: () => Promise<'granted' | 'denied'>
}

export function useDeviceOrientation() {
  const [currentHeading, setCurrentHeading] = useState<number>(0)

  const initDeviceOrientation = async (onRequestPermission?: (status: string) => void) => {
    if (DeviceOrientationEvent as unknown as DeviceOrientationEventiOS) {
      let status = 'granted'
      const DevicerientationEventKlass =
        DeviceOrientationEvent as unknown as DeviceOrientationEventiOS
      if (typeof DevicerientationEventKlass.requestPermission === 'function') {
        status = await DevicerientationEventKlass.requestPermission()
        if (onRequestPermission) onRequestPermission(status)
      }
      if (status === 'granted') {
        watchDeviceOrientation()
      }
    }
  }

  const handleDeviceOrientation = (event: DeviceOrientationEvent) => {
    setCurrentHeading(event.alpha ?? 0)
  }

  const watchDeviceOrientation = () => {
    removeDeviceOrientation()
    window.addEventListener('deviceorientation', handleDeviceOrientation)
  }

  const removeDeviceOrientation = () => {
    window.removeEventListener('deviceorientation', handleDeviceOrientation)
  }

  return {
    initDeviceOrientation,
    currentHeading,
    watchDeviceOrientation,
    removeDeviceOrientation,
  }
}
