import { useEffect } from 'react'
import { waitUser, WaitUserPromiseArgType } from '~/utils/firebase.client'
import { redirect, useSubmit } from 'react-router'
import { authenticator, isAuthenticated } from '~/services/auth.server'
import { sessionStorage } from '~/services/session.server'
import { Spinner } from 'flowbite-react'
import type { Route } from './+types/login'

function getRedirectUrl(request: Request) {
  const url = new URL(request.url)
  const redirectToEncoded = url.searchParams.get('redirectTo') ?? '/'
  return decodeURIComponent(redirectToEncoded)
}

export async function loader({ request }: Route.LoaderArgs) {
  const user = await isAuthenticated(request, false)
  if (user) {
    const redirectTo = getRedirectUrl(request)
    throw redirect(redirectTo)
  }
  return user
}

export async function action({ request }: Route.ActionArgs) {
  const redirectTo = getRedirectUrl(request)

  const session = await authenticator.authenticate( 'imako-authentication', request)
  const oldSession = await sessionStorage.getSession(request.headers.get('cookie'))
  oldSession.set('user', session)

  throw redirect(redirectTo, {
    headers: { 'Set-Cookie': await sessionStorage.commitSession(oldSession) },
  })
}

export default function Login() {
  const submit = useSubmit()

  let isWaitUser = false
  useEffect(() => {
    if (isWaitUser) return

    waitUser().then((value) => {
      const { token } = value as WaitUserPromiseArgType
      setTimeout(() => {
        submit({ token }, { method: 'post' })
      }, 100)
    })
    return () => {
      isWaitUser = true
    }
  }, [])

  return (
    <div
      className={`bg-gray-400/50 
                absolute
                top-50
                left-0
                w-full
                h-full
                z-10
                overflow-y-visible
                content-center
                text-center`}
    >
      <div className="flex justify-center items-center gap-10">
        <div className="text-xl">Loading</div>
        <Spinner aria-label="Loading" size="xl" className="z-20" color="gray" />
      </div>
    </div>
  )
}
